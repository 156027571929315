<template>
  <div :class="'iq-card ' + className" >
    <slot name="cardImage"/>
    <div v-if="hasHeaderTitleSlot || hasHeaderActionSlot" :class="'iq-card-header d-flex justify-content-between '+headerClass">
      <div class="iq-header-title">
        <slot name="headerTitle" />
      </div>
      <div class="iq-card-header-toolbar d-flex align-items-center">
        <slot name="headerAction" />
      </div>
    </div>
    <div :class="'iq-card-body ' + bodyClass" v-if="hasBodySlot">
      <slot name="body"/>
    </div>
    <slot />
    <div v-if="hasFooterSlot" :class="'card-footer' + footerClass">
      <slot name="footer"/>
    </div>
  </div>
</template>
<script>
export default {
  name: 'iq-card',
  props: {
    className: { type: String, default: '' },
    bodyClass: { type: String, default: '' },
    headerClass: { type: String, default: '' },
    footerClass: { type: String, default: '' }
  },
  mounted () {
  },
  computed: {
    hasHeaderTitleSlot () {
      return !!this.$slots.headerTitle
    },
    hasHeaderActionSlot () {
      return !!this.$slots.headerAction
    },
    hasBodySlot () {
      return !!this.$slots.body
    },
    hasFooterSlot () {
      return !!this.$slots.footer
    }
  }
}
</script>

<style scoped>
/* Modificaciones para el calendario */
.card-calendar{
  margin-bottom: 10px!important;
}
.task-body{
  padding: 15px 5px 5px 5px;
}
.task-header{
  padding: 3px!important;
  background-color: #142b73;
  border: solid 1px#0e1e50;
}
.task-card{
  padding: 1px!important;
  text-align: center;
}
.task-card-title{
  padding: 5px 1px 1px 1px!important;
  text-align: center;
  min-height: 60px;
}
.coproperties{
  box-shadow: 5px 8px 20px #cecece;
  border-top:10px solid #142b73;
  margin-bottom: 20px;
  transition: 0.8s;
}

.coproperties:hover{
  /* width: 102%;
  height: 102%; */
  padding: 7px 1px ;
  box-shadow: 5px 8px 15px #f0c17c;
}

.body-coproperties{
  min-height:140px ;
}

.header-coproperties{
  font-size: 22px;
  letter-spacing: 1px;
  font-weight: bold;
  color: rgb(26, 26, 26);
  display: block;
  width: 100%;
  height: 150px;
}

.header-properties{
  font-size: 22px;
  letter-spacing: 1px;
  font-weight: bold;
  color: rgb(26, 26, 26);
  display: block;
  width: 100%;
  height: 130px;
}
.header-small{
  min-height: 30px;
  padding: 3px!important;
  background-color: #142b73;
  border: solid 1px#0e1e50;
}
.listing-card {
  margin-bottom: 10px;
}
@media only screen and (max-width: 600px) {
  .header-properties{
    font-size: 15px;
    letter-spacing: 1px;
    font-weight: bold;
    color: rgb(26, 26, 26);
    display: block;
    width: 100%;
    height: 110px;
  }
  .header-coproperties{
    font-size: 15px;
    letter-spacing: 1px;
    font-weight: bold;
    color: rgb(26, 26, 26);
    display: block;
    width: 100%;
    height: 120px;
  }
}
</style>
