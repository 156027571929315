import Vue from 'vue'
import VueRouter from 'vue-router'
import { db, firebaseApp } from '@/config/firebase'
const Layout2 = () => import('../layouts/Layout2.vue')

const AuthLayout = () => import('../layouts/AuthLayouts/AuthLayout')
/* Dashboards View */
// const Dashboard1 = () => import('../views/Dashboards/Dashboard1.vue')
// const Dashboard2 = () => import('../views/Dashboards/Dashboard2.vue')

/* Authentic View */
const SignIn1 = () => import('../views/AuthPages/Default/SignIn1')
const SignUp1 = () => import('../views/AuthPages/Default/SignUp1')
const RecoverPassword1 = () => import('../views/AuthPages/Default/RecoverPassword1')
const LockScreen1 = () => import('../views/AuthPages/Default/LockScreen1')
const ConfirmMail1 = () => import('../views/AuthPages/Default/ConfirmMail1')
const Callback = () => import('../views/AuthPages/Default/Callback')

/* Extra Pages */
const ErrorPage = () => import('../views/Pages/ErrorPage')

/* Admin Views */
const AdminLayout = () => import('../layouts/AdminLayout.vue')
const DashboardAdmin = () => import('../views/Dashboards/DashboardAdmin')
const Halloween = () => import('../views/Admin/Halloween.vue')
const Contacts = () => import('../views/Admin/Contacts.vue')
const Product = () => import('../views/Admin/Products.vue')
const Provider = () => import('../views/Admin/Provider.vue')
const Assemblies = () => import('../views/Admin/Assemblies.vue')
const Editing = () => import('../views/Admin/fullrowediting.vue')
const Index = () => import('../views/Admin/indexCreations.vue')
const staffProfile = () => import('../views/Admin/staffProfile.vue')
const ProfileEditCurrent = () => import('../views/Admin/ProfileEditCurrent.vue')
const ProjectBoard = () => import('../views/Admin/ProjectBoard.vue')
const importDB = () => import('../views/Admin/importDB.vue')
const Zoom = () => import('../views/Admin/property/ViewZoom.vue')
const Videocall = () => import('../views/Admin/property/Videocall.vue')
const Meeting = () => import('../views/Admin/property/ViewMeeting.vue')

/* Super Admin views */
const SingInAdmin = () => import('../views/SuperAdmin/Auth/SingIn.vue')
const SuperAdminLayout = () => import('../layouts/SuperAdminLayout.vue')
const DashboardSuperAdmin = () => import('../views/Dashboards/DashboardSuperAdmin.vue')
const CoPropertiesSuperAdmin = () => import('../views/SuperAdmin/CoProperty.vue')

const EditAdmin = () => import('../views/SuperAdmin/EditAdmin.vue')

Vue.use(VueRouter)

const authChildRoutes = (prop) => [
  {
    path: 'sign-in1',
    name: prop + '.sign-in1',
    meta: { },
    component: SignIn1
  },
  {
    path: 'sign-up1',
    name: prop + '.sign-up1',
    meta: { auth: true },
    component: SignUp1
  },
  {
    path: 'password-reset1',
    name: prop + '.password-reset1',
    meta: { auth: true },
    component: RecoverPassword1
  },
  {
    path: 'lock-screen1',
    name: prop + '.lock-screen1',
    meta: { auth: true },
    component: LockScreen1
  },
  {
    path: 'confirm-mail1',
    name: prop + '.confirm-mail1',
    meta: { auth: true },
    component: ConfirmMail1
  }
]

const routes = [
  {
    path: '',
    name: 'dashboard',
    component: Layout2,
    meta: { authInit: true },
    children: [
      {
        path: 'staffProfile/:idDoc',
        meta: { auth: true },
        name: 'dashboard.staffProfile',
        component: staffProfile
      }
    ]
  },
  // RUTAS ADMINISTRADOR
  {
    path: '/admin-dashboard',
    name: 'dashboard-admin',
    component: AdminLayout,
    meta: { auth: true },
    children: [
      {
        path: 'home',
        name: 'dashboardAdmin.home',
        meta: { auth: true, name: 'Home 1' },
        component: DashboardAdmin
      },
      {
        path: 'hallowen',
        meta: { auth: true },
        name: 'dashCoproperty.halloween',
        component: Halloween
      },
      {
        path: 'contactos',
        meta: { auth: true },
        name: 'dashCoproperty.contacts',
        component: Contacts
      },
      {
        path: 'productos',
        meta: { auth: true },
        name: 'dashCoproperty.product',
        component: Product
      },
      {
        path: 'provider',
        meta: { auth: true },
        name: 'dashCoproperty.provider',
        component: Provider
      },
      {
        path: 'asambleas',
        meta: { auth: true },
        name: 'dashCoproperty.assemblies',
        component: Assemblies
      },
      {
        path: 'importDB',
        meta: { auth: true },
        name: 'dashCoproperty.importDB',
        component: importDB
      },
      {
        path: 'videocall',
        meta: { auth: true },
        name: 'dashCoproperty.videocall',
        component: Videocall
      },
      {
        path: 'editing',
        meta: { auth: true },
        name: 'dashCoproperty.editing',
        component: Editing
      },
      {
        path: 'index',
        meta: { auth: true },
        name: 'dashCoproperty.index',
        component: Index
      },
      {
        path: 'staffProfile/:idDoc',
        meta: { auth: false },
        name: 'dashCoproperty.staffProfile',
        component: staffProfile
      },
      {
        path: 'profileeditcurrent/:idDoc',
        meta: { auth: true },
        name: 'dashCoproperty.ProfileEditCurrent',
        component: ProfileEditCurrent
      },
      {
        path: 'project',
        meta: { auth: true },
        name: 'dashCoproperty.project-board',
        component: ProjectBoard
      }
    ]
  },
  {
    path: '/zoom/:idCoProperty/:meetingId',
    meta: { auth: true },
    name: 'dashCoproperty.videoCall.zoom',
    component: Zoom,
    children: [
      {
        path: '',
        meta: { auth: true },
        name: 'dashCoproperty.videoCall',
        component: Videocall
      },
      {
        path: 'meet/:meetingId',
        meta: { auth: true },
        name: 'dashCoproperty.videoCall.meeting',
        component: Meeting
      }
    ]
  },
  {
    path: '*',
    component: ErrorPage
  },
  // ROUTER SUPER ADMIN
  {
    path: '/sv-admin-clase-alpha/',
    name: 'authSuperAdmin',
    component: AuthLayout,
    meta: { authSuperAdmins: true },
    children: [
      {
        path: 'sing-in',
        meta: { authSuperAdmins: true },
        name: 'superAdmin.sing-in',
        component: SingInAdmin
      }
    ]
  },
  {
    path: '/sv-admin-clase-alpha/super-admin',
    name: 'layoutSuperAdmin',
    component: SuperAdminLayout,
    meta: { authSuperAdmins: true },
    children: [
      {
        path: 'dashboard',
        meta: { authSuperAdmins: true },
        name: 'superadmin.dashboard',
        component: DashboardSuperAdmin
      },
      {
        path: 'edit/:idDoc',
        meta: { authSuperAdmins: true },
        name: 'superadmin.edit',
        component: EditAdmin
      },
      {
        path: 'co-properties',
        meta: { authSuperAdmins: true },
        name: 'superadmin.coproperties',
        component: CoPropertiesSuperAdmin
      }

    ]
  },
  {
    path: '/auth',
    name: 'auth1',
    component: AuthLayout,
    meta: { auth: true },
    children: authChildRoutes('auth1')
  },
  {
    path: '/callback',
    name: 'callback',
    meta: { auth: false },
    component: Callback
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/auth/sign-in1', '/auth/sign-up1', '/dark/auth/sign-in1', '/dark/auth/sign-up1']
  if (publicPages.includes(to.path)) {
    localStorage.removeItem('user')
    localStorage.removeItem('access_token')
  }
  if (to.meta.authInit) {
    console.log('here')
    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        db.collection('administrators')
          .where('uid', '==', user.uid)
          .get()
          .then((doc) => {
            if (doc.docs.length === 0) {
              db.collection('/campus/Vs2FkUx38u3W8IDuABNF/contacts/')
                .where('email', '==', user.email)
                .get()
                .then((doc) => {
                  if (doc.docs.length === 0) {
                    return next('/auth/sign-in1')
                  } else {
                    return next(`/staffProfile/${doc.id}/`)
                  }
                })
            } else {
              return next('/admin-dashboard/home')
            }
          })
      } else {
        return next('/auth/sign-in1')
      }
    })
  }
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')
  if (to.meta.auth) {
    if (authRequired && loggedIn === null) {
      return next('/auth/sign-in1')
    } else if (to.name === 'dashboard') {
      return next('/admin-dashboard/home')
    }
    next()
  } else {
    if (to.meta.authSuperAdmins) {
      if (to.path === '/sv-admin-clase-alpha/sing-in') {
        if (localStorage.getItem('user') === null) {
          next()
        } else {
          return next('/sv-admin-clase-alpha/super-admin/dashboard')
        }
      } else {
        if (to.path !== '/sv-admin-clase-alpha/sing-in' && localStorage.getItem('user') === null) {
          return next('/sv-admin-clase-alpha/sing-in')
        } else {
          next()
        }
      }
    } else {
      if (to.meta.authUser) {
        if (authRequired && loggedIn === null) {
          return next('/auth/sign-in1')
        } else if (to.name === 'dashboard') {
          return next('/user-dashboard/home')
        }
      }
    }
  }
  next()
})

export default router
