export default {
  webBaseURL: process.env.MIX_APP_URL,
  firebaseConfig: {
    apiKey: 'AIzaSyCfUSrlu-pLlXX63-Lf7l8SYg-epwHavb4',
    authDomain: 'app-clasealpha-prueba.firebaseapp.com',
    databaseURL: 'https://app-clasealpha-prueba-default-rtdb.firebaseio.com',
    projectId: 'app-clasealpha-prueba',
    storageBucket: 'app-clasealpha-prueba.appspot.com',
    messagingSenderId: '583743182069',
    appId: '1:583743182069:web:13524b75a93f9ac89761bc'
  },
  auth0Config: {
    domain: '',
    clientID: '',
    // make sure this line is contains the port: 8080
    redirectUri: 'http://localhost:8080/callback',
    // we will use the api/v2/ to access the user information as payload
    audience: 'https://dev-6u-3fb22.auth0.com/api/v2/',
    responseType: 'token id_token',
    scope: 'openid profile'
  }
}
