import firebase from 'firebase'
import constant from './constant'
let dbInit
let storage
let firebaseAppInit
let firebaseAppInit2
if (process.env.VUE_APP_FIREBASE === 'true') {
  firebaseAppInit = firebase.initializeApp(constant.firebaseConfig)
  firebaseAppInit2 = firebase.initializeApp(constant.firebaseConfig, 'Secondary')

  dbInit = firebaseAppInit.firestore()
  storage = firebaseAppInit.storage()
}
export const db = dbInit
export const st = storage
export const firebaseApp = firebaseAppInit
export const firebaseApp2 = firebaseAppInit2
